<template>
  <div class="col-12">
    <br>
    <div class="card">
        <div class="card-header">
            <h5>
                <span>تقرير تحضير دروس المعلمين بتاريخ <i id="date"></i></span>
                &nbsp;
                <b-dropdown
                id="dropdown-2"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="خيارات"
                variant="success"
                >
                <b-dropdown-item @click="viewdetails()">
                    <i class="fa fa-eye"></i>
                    عرض تفاصيل كل الدروس
                </b-dropdown-item>
                </b-dropdown>
                &nbsp;
                <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="📥 طباعة / تصدير"
                variant="primary"
                >
                <b-dropdown-item @click="printDiv()">
                    <i class="fa fa-print"></i>
                    طباعة التقرير
                </b-dropdown-item><hr>
                <b-dropdown-item @click="printDiv2()">
                    <i class="fa fa-print"></i>
                    طباعة ملونة
                </b-dropdown-item><hr>
                <b-dropdown-item @click="exportToExcel()">
                    <i class="fa fa-download"></i>
                    تصدير التقرير اكسل
                </b-dropdown-item><hr>
                <b-dropdown-item @click="saveToArchive()">
                    <i class="fa fa-archive"></i>
                    حفط في سجل التقارير
                </b-dropdown-item>
                </b-dropdown>
            </h5>
        </div>
        <div class="card-body" id="headerTable">
            <div class="col-12 table-responsive" >
                <span class="dnone">تقرير تحضير دروس المعلمين بتاريخ <span id="date2"></span></span>
                <table class="table table-hover table-bordered ddcc table-sm">
                    <thead>
                        <th>م</th>
                        <th>الاسم</th>
                        <th>الأولى</th>
                        <th>الثانية</th>
                        <th>الثالثة</th>
                        <th>الرابعة</th>
                        <th>الخامسة</th>
                        <th>السادسة</th>
                        <th>السابعة</th>
                        <th>الثامنة</th>
                        <th>التاسعة</th>
                        <th>العاشرة</th>
                    </thead>
                    <tbody></tbody>
                </table>
            </div>
        </div>
    </div>
    <textarea class='get-local-storage' name="teachersLessonsHTML" style='display: none'></textarea>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            loading: false,
            view_archive: false,
            selected: null,
            school_id: localStorage.getItem("school_id"),
            days: {
                0: "الاحد",
                1: "الاثنين",
                2: "الثلاثاء",
                3: "الاربعاء",
                4: "الخميس",
                5: "الجمعة",
                6: "السبت"
            },
            teachers: [],
            result: [],
            type: window.location.href.split("type=")[1].split("&")[0],
            message: `الاستاذ {name}
لم يتم اعداد الحصص التالية:
{lessons}
ليوم {day} الموافق {date}`
        }
    },
    created(){
        var g = this;
        var int = setInterval(() => {
            if($(".get-local-storage-value")?.length){
                clearInterval(int)
                var result = JSON.parse($(".get-local-storage-value").val())
                g.result = result
                var index = 1;
                $.each(result, function (i, teacher) {

                    $("#date").html(teacher.data.CurrentSelectedDateStringHijri + " || " + teacher.data.CurrentSelectedDateString)

                    if (teacher.data.TimeTable[0]) {

                        var lessons = "";
                        var details = "";
                        var ua = "";

                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach(function (i) {

                            lessons = lessons + "<td>";

                            $.each(teacher.data.TimeTable, function (ia, table) {

                                if (table.SlotNumber == i) {

                                    if (table.PresenterUrl != null) {

                                        lessons = lessons + "<span class='btn btn-block btn-success aa btn-sm'>√ " + table.ClassRoomName + "</span>";
                                        ua = `
                                    <small>
                                    رابط Teams: <a target='_blank' href='` + table.PresenterUrl + `'>إضغط هنا</a>
                                    </small>
                                    <br>
                                    <small>
                                    تفاصيل الدرس: <a target='_blank' href='https://schools.madrasati.sa/Teacher/Lessons/viewLectureDetails?Data=` + table.Data + `'>إضغط هنا</a>
                                    </small>
                                    <br>
                                    `;

                                    } else {

                                        if (table.LessonType == 2) {

                                            lessons = lessons + "<span class='btn btn-block btn-info aa btn-sm'>√ " + table.ClassRoomName + " <small class='dnone'><br>غير متزامن</small></span>";
                                            ua = `<small class='text-primary'>
                                                غير متزامن
                                                <br>
                                    <small>
                                    تفاصيل الدرس: <a target='_blank' href='https://schools.madrasati.sa/Teacher/Lessons/viewLectureDetails?Data=` + table.Data + `'>إضغط هنا</a>
                                    </small>
                                        </small>
                                    <br>`;

                                        } else {

                                            if (table.LessonType == 3) {

                                                lessons = lessons + "<span class='btn btn-block btn-primary aa btn-sm'>√ " + table.ClassRoomName + " <small class='dnone'> غير متزامن (معياري)</small></span>";
                                                ua = `<small class='text-primary'>
                                                    غير متزامن (معياري)
                                                    <br>
                                        <small>
                                        تفاصيل الدرس: <a target='_blank' href='https://schools.madrasati.sa/Teacher/Lessons/viewLectureDetails?Data=` + table.Data + `'>إضغط هنا</a>
                                        </small>
                                            </small>
                                        <br>`;

                                            } else {

                                                lessons = lessons + "<span class='btn btn-block btn-danger aa btn-sm'>X " + table.ClassRoomName + "</span>";
                                                ua = `<small class='text-danger'>
                                                لم يتم إعداد الدرس
                                            </small>
                                            <br>`;

                                            }

                                        }

                                    }

                                    details = `
                                <div class='info' style='display: none; border: 1px solid #eee; padding: 2px;'>
                                    <small>
                                        عنوان الدرس: ` + table.SubjectName + `
                                    </small>
                                    <br>
                                    <small>
                                        التوقيت: ` + table.StartTime + ` - ` + table.EndTime + `
                                    </small>
                                    <br>
                                    ` + ua + `
                                </div>
                                `;

                                    lessons = lessons + details;

                                }

                            });
                            lessons = lessons + "</td>";

                        });

                        if(g.type == "1" || g.type == "2" && lessons.includes("لم يتم")){
                            $("tbody").append(`
                        <tr>
                            <td>` + index + `</td>
                            <td>` + teacher.name + `<br><small>${teacher.school_name}</small></td>
                            ` + lessons + `
                        </tr>
                    `);
                        index = index + 1;
                        }

                    }else{
            var lessons = "";

            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach(function (i) {

                lessons = lessons + "<td></td>";

            })
            
                        if(g.type == "1"){
            index = index + 1;
            $("tbody").append(`
            <tr>
                <td>` + index + `</td>
                <td>` + teacher.name + `<br><small>${teacher.school_name}</small></td>
                ` + lessons + `
            </tr>
        `);}
                    }

                });
                $(".table-responsive").append(`
                <table class='table table-bordered table-sm'>
                    <thead>
                    <th>
                        
                    </th>
                        <th>
                            
                        </th>
                        <th>
                            كل الدروس
                        </th>
                        <th>
                            عدد الدروس الغير متزامنة
                        </th>
                        <th>
                            عدد الدروس المُعدة
                        </th>
                        <th>
                            عدد الدروس الغير المُعدة
                        </th>
                    </thead>
                    <tbody>
                        <tr>
                        <td>
                        </td>
                            <td>
                                الإجمالي:
                            </td>
                            <td class='ag1'>
                            
                            </td>
                            <td class='ag2'>
                            
                            </td>
                            <td class='ag3'>
                            
                            </td>
                            <td class='ag4'>
                            
                            </td>
                        </tr>
                    </tbody>
                </table>
                    `);
                    $(".ag1").html($(".aa").length)
                    $(".ag2").html($(".btn-info.aa").length)
                    $(".ag3").html($(".btn-success.aa").length + $(".btn-info.aa").length)
                    $(".ag4").html($(".btn-danger.aa").length)
                    $(".aa").click(function () {

                        $(this).parent().find(".info").toggle();

                    });

            }
        }, 100);
    },
    methods: {
        printDiv2(){
            $(".dropdown-toggle").fadeOut(0)
            setTimeout(() => {
                window.print()
                $(".dropdown-toggle").fadeIn()
            }, 500);
        },
        viewdetails(){
            var g = this;
            $(".aa").each(function(){
                $(this).click()
            })
        },
        exportToExcel() {
             $(".info").each(function(){
                if(!$(this).is(":visible")){
                    $(this).remove()
                }
            })
            $("td").each(function(){
                if($(this).find(".btn-success").length){
                    $(this).attr("style", `background: #97DECE`)
                }
                if($(this).find(".btn-danger").length){
                    $(this).attr("style", `background: #FD8A8A`)
                }
                if($(this).find(".btn-info").length){
                    $(this).attr("style", `background: #82AAE3`)
                }
            })
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").innerHTML +
                '</body> ' +
                '</html>'
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
            setTimeout(() => {
                window.location = window.location.href
            }, 1000);
        },
        printDiv() {
            document.getElementById("date2").innerHTML = document.getElementById("date").innerHTML
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                .btn-danger{
                    background: #FD8A8A;
                }
                .btn-success{
                    background: #97DECE;
                }
                .btn-info{
                    background: #82AAE3;
                }
                .btn{
                    width: 100% !important;
                    padding-left: 2px;
                    padding-right: 2px;
                }
                </style>`);
            newWin.document.close();
            newWin.print();
            newWin.close();
        }
    }
}
</script>

<style>
.dropdown-menu{
    width: 300px !important
}
</style>